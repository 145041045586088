import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-12 offset-md-2 intro-text text-center">
                <h1
                  className="text-primary fw-bold display-4"
                  style={{
                    color: "#88daf3",
                    fontSize: "clamp(2rem, 5vw, 5rem)",
                  }}
                >
                  {props.data ? props.data.title : "Loading"}
                </h1>

                <p className="fs-5 fw-500 mt-4">
                  {props.data ? props.data.paragraph : "Loading"}
                </p>

                <a
                  className="btn btn-custom btn-lg mt-4"
                  href="#contact"
                  style={{ fontWeight: "bold" }}
                >
                  Make Appointment
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
