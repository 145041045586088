import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="bg-indigo-100 w-100 min-vh-100 d-flex align-items-center justify-content-center">
      <div className="container text-center">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Features</h2>
        </div>

        <div className="row justify-content-center">
          {
            props.data
              ? props.data.map((d, i) => {
                return (
                  <div className="col-12 col-md-6 col-lg-3 mb-5 d-flex align-items-stretch">
                    <div className="card w-100 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <i
                          className={`${d.icon} fa-4x`}
                        ></i>
                      </div>
                      <div className="card-body d-flex flex-column align-items-center bg-purple-700 rounded-lg p-4">
                        <h5 className="card-title text-center text-white font-bold">
                          {d.title}
                        </h5>

                        <p className="card-text text-center text-xs text-gray-400">
                          {d.text}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })
              :
              "Loading.."
          }
        </div>

      </div>
    </div>


    // <div id="features" className="text-center">
    //   <div className="container">
    //     <div className="col-md-10 col-md-offset-1 section-title">
    //       <h2>Features</h2>
    //     </div>
    //     <div className="row">
    //       {props.data
    //         ? props.data.map((d, i) => (
    //             <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
    //               {" "}
    //               <i className={d.icon}></i>
    //               <h3>{d.title}</h3>
    //               <p>{d.text}</p>
    //             </div>
    //           ))
    //         : "Loading..."}
    //     </div>
    //   </div>
    // </div>
  );
};
